@import "vars";

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	/**/
	.s-window-tabs, .s-price-windows {
		.tabs {
			li {
				padding: 10px 10px;
				font-size: 14px; } } }

	.s-price-windows .price-win {
	 	width: 100%; }
	.s-price-windows .window_type {
		font-size: 13px; }
	.profiles .item {
		padding-left: 10px;
		padding-right: 10px; }
	.prev-works .wrap-items {
		flex-wrap: wrap;
		justify-content: center; }
	.prev-works .item {
		flex-basis: 50%; }
	.wrap-calc {
		padding: 15px; }
	.wrap-calc {
		width: 970px; }

	.s-part-balcony .slick-next {
		top: 23%; }
	.s-part-balcony .slick-prev {
		top: 23%; } }



/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	/**/
	.our-advantages {
		.wrap-items {
			flex-wrap: wrap; }
		.item {
			flex-basis: 50%;
			margin: 15px 0; } }
	.call-master {
		font-size: 18px;
		.img-block {
			max-width: 300px; } }
	.wrap-calc {
		max-width: 750px;
		.wrap-items {
			flex-wrap: wrap; }
		.gabarites {
			flex-basis: 100%;
			margin-right: 0;
			margin-bottom: 15px;
			margin-left: 80px;
			input {
				max-width: 90px; } }
		.height-win {
			display: inline-block;
			width: 100px;
			margin-right: 20px; }
		.width-win {
			display: inline-block;
			width: 100px; }
		.wrap-output {
			margin-top: 20px; } }
	.mobil-mnu {
		display: block; }
	.header-mnu {

		ul {
			width: 100%;
			background-color: rgba(255,255,255,.95);
			position: absolute;
			left: 0;
			top: 90px;
			padding-top: 15px;
			padding-bottom: 40px;
			display: none;
			li {
				display: block; }
			a {
				display: block;
				padding: 10px 15px;
				font-weight: bold; } } }
	.header-phone {
		margin-right: 20px; }
	.wrap-calc {}

	.wrap-kharakteristik {
		flex: 0 0 100%; }
	.calc_window_profile {
		margin: 50px auto 0;
		max-width: 290px;
		display: block; }
	.x-start {
		top: 15px;
		left: 0px; }
	.x-end {
		top: 15px;
		right: 0px; }
	.s-small-images .left-images .item {
		justify-content: space-between; }
	.s-small-images .item .img-bkock {
	    width: 70px;
	    min-width: 70px;
	    height: 70px; }
	.s-reg {
		.reg-block {
			max-width: 125px; }
		.title {
			line-height: 15px; } }
	.s-reg .wrap-image {
		max-width: 445px; }
	.s-reg .descr {
		line-height: 1.2; }
	.s-reg {
		.reg3 {
			position: absolute;
			top: 170px;
			left: -70px; }
		.reg5 {
			position: absolute;
			top: 140px;
			right: -90px; } }
	.s-pic-balcony .img-block {
		max-width: 520px; }
	.s-pic-balcony {
		.balcon-des {
			max-width: 130px;
			font-size: 14px; }
		.bal1 {
			top: 15%; }
		.bal2 {
			top: 22%; }
		.bal3 {
			top: 37%; }
		.bal4 {
			top: 57%; }
		.bal5 {
			top: 68%; }
		.bal6 {
			top: 76%; }
		.bal7 {
			top: 85%; }
		.bal8 {
			top: 35%; }
		.bal9 {
			top: 60%; }
		.bal10 {
			top: 86%; } }
	.s-part-balcony .slick-next {
		right: 0; }
	.s-part-balcony .slick-prev {
		left: -15px; } }






/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	/**/
	.s-window-tabs .right-item {
		display: block;
		padding-left: 5px; }
	.s-window-tabs .rassr {
		display: block; }
	.s-window-tabs .left-item {
		flex-basis: 100%; }
	.s-window-tabs .right-item img {
		margin-left: -20px; }
	.s-window-tabs {
		.tabs {
			li {
				padding: 10px 5px;
				font-size: 12px;
				text-align: center; } } }
	.s-window-tabs .tabs li.active {
		border-bottom: 2px solid #f7b70b; }
	.header-content .wrap-content {
		padding-left: 10px;
		padding-right: 10px; }
	.h2, h2 {
		font-size: 25px; }
	.header-content p {
		font-size: 18px; }
	.tabs-cont-window .line {
		width: 18%; }
	.s-bottom-form .wrap-items {
		display: block; }
	.s-bottom-form .content-block {
		margin-top: 30px;
		h2 {
			font-size: 30px;
			text-align: center; } }
	.s-bottom-form .content-block .descr {
		font-size: 18px;
		text-align: center; }
	.contact-block {
		.wrap-items {
			flex-wrap: wrap;
			justify-content: center; }
		.item {
			flex-basis: 50%;
			padding: 15px 0; } }
	.prev-works .item {
		flex-basis: 100%; }
	body {
		font-size: 14px; }
	.descr-advantages .item {
		flex-basis: 100%; }
	.s-type-balcony .wrap-items {
		flex-wrap: wrap;
		.item {
			flex-basis: 100%;
			margin: 15px 0; } }
	.our-advantages {
		padding: 30px 0;
		span[class^=icon] {
			font-size: 30px; }
		.title {
			font-size: 30px;
			margin-top: 15px; }
		.descr {
			font-size: 12px; } }
	.call-master .img-block {
		display: none; }
	.call-master .form-block {
		max-width: 450px;
		margin: auto;
		padding-right: 15px; }
	.logo img {
		max-width: 160px; }
	.mobil-mnu {
		margin-right: 10px; }
	.wrap-calc {
		width: 450px; }
	.x-slider {
		min-width: 450px;
		bottom: -25px; }
	.wrap-calc .big-pic {
		width: 370px; }
	.window-content {
		.two-window {
			.dinamic-block-left {
				.dinamic-pic {
					right: 32px; } }
			.open-type-left {
				right: 60px; } }
		.third-window {
			.open-type {
				left: 13px; }
			.dinamic-block-right .dinamic-pic {
				height: 200px; }
			.dinamic-block-left {
				.open-type {
					left: auto;
					right: 20px; } }
			.dinamic-pic {
				height: 200px; }
			.dinamic-block-center {
				.open-type {
					left: 130px; }
				.dinamic-pic {
					left: 125px; } } }
		.big-window {
			.dinamic-pic {
				height: 130px;
				top: 8px; }
			.dinamic-block-left {
				.dinamic-pic {
					left: 135px; }
				.open-type {
					left: 140px; } }
			.dinamic-block-right .open-type {
				left: 20px; } } }




	.twoTop-window .dinamic-block-left .dinamic-pic {
		right: 35px; }
	.twoTop-window .dinamic-block-left .open-type {
		right: 64px; }
	.s-small-images {
		padding-left: 15px;
		padding-right: 15px;
		.big-images {
			display: none; } }
	.s-reg .wrap-image {
		max-width: 75%; }
	.s-reg .reg-block {
		max-width: 100px; }
	.s-reg {
		padding-bottom: 0;
		.reg4 {
			position: absolute;
			top: 26px;
			right: -64px; }
		.reg5 {
			right: -65px; }
		.reg1 {
			left: -60px; }
		.reg2 {
			left: -60px; }
		.reg3 {
			left: -60px; } }
	.big-buttons {
		a {
			font-size: 12px;
			height: 40px; } }
	.s-pic-balcony .img-block {
		max-width: 350px; }
	.s-pic-balcony .balcon-des {
		font-size: 12px;
		max-width: 100px; }
	.s-pic-balcony {
		.descr {
			font-size: 10px; }
		.bal1 {
			left: -10%; }
		.bal2 {
			left: -10%;
			top: 24%; }
		.bal3 {
			left: -10%; }
		.bal4 {
			left: -10%; }
		.bal5 {
			left: -10%; }
		.bal6 {
			left: -10%;
			top: 78%; }
		.bal7 {
			left: -10%; }
		.bal8 {
			right: -20%; }
		.bal9 {
			right: -20%; }
		.bal10 {
			right: -20%; } }
	.s-part-balcony {
		.wrap-slider {
			margin: 0 25px; } }
	.profile {
		&:hover {
			.calc_window_profile_info {
				display: none; } } }
	.calc_window_profile_selector table {
		width: 100%; }
	.button-block-next {
		text-align: center;
		.button {
			top: 0;
			margin-top: 30px; } }
	.wrap-calc .gabarites {
		margin-left: 0;
		margin-bottom: 30px; }
	.wrap-calc .wrap-form {
		width: auto; }
	.s-small-images .small-slider {
		display: block; }
	.s-small-images {
		.wrap-items {
			display: none; }
		.descr {
			display: none; } }
	.s-small-images .item .img-bkock {
		order: -1; } }




/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	/**/
	.s-window-tabs .left-imgs ul {
		width: 100%; }
	.s-window-tabs .garanty-block {
		display: block; }
	.s-window-tabs .right-item {
		display: flex; }
	.header-content h2 {
		font-size: 25px; }
	.h2, h2 {
		font-size: 20px; }
	.contact-block .item {
		flex-basis: 100%;
		border-right: none;
		border-bottom: 1px solid $accent; }
	.prev-works .lay-block {
		width: 100%; }
	.descr-advantages .img-block {
		min-width: 55px;
		font-size: 30px; }
	.call-master .form-block {
		max-width: 300px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 16px; }
	.call-master form {
		display: block; }
	.header-mnu .wrap-items {
		flex-wrap: wrap;
		min-height: 50px; }
	.header-mnu ul {
		top: 50px; }
	.header-phone {
		margin-right: 70px;
		font-size: 14px; }
	.mobil-mnu {
		position: absolute;

		top: 15px;
		right: 10px; }
	.wrap-calc {
		width: 290px; }
	.x-slider {
		min-width: 290px;
		bottom: -25px; }
	.wrap-calc .big-pic {
		width: 220px; }
	.y-slider .ui-widget.ui-widget-content {
		height: 220px; }
	.y-slider {
		margin-right: 15px; }
	.wrap-calc .but-first .button {
		min-width: 110px; }
	.wrap-calc .preview-images label {
		margin: 5px; }
	.calc_window_profile_selector table {
		width: 290px; }
	.s-small-images .wrap-items {
		flex-wrap: wrap; }

	.s-small-images .left-images .item {
		justify-content: flex-start; }

	.s-reg {
		padding-bottom: 20px;
		.reg-block {
			max-width: 90px; }
		.reg4 {
			top: 26px;
			right: -50px; }
		.reg5 {
			right: -50px; }
		.reg1 {
			left: -15px;
			top: -35px; }
		.reg2 {
			left: -35px;
			top: 20px; }
		.reg3 {
			left: -35px;
			top: 115px; }
		.title {
			font-size: 12px; }
		.descr {
			font-size: 10px; } }
	.button-block .button.accent {
		display: block;
		margin: 15px;
		width: auto; }
	.s-pic-balcony .img-block {
		max-width: 260px; }
	.s-pic-balcony .balcon-des {
		font-size: 10px;
		max-width: 90px; }
	.s-pic-balcony {
		.descr {
			font-size: 8px; }
		.bal1 {
			left: -10%; }
		.bal2 {
			left: -10%;
			top: 24%; }
		.bal3 {
			left: -10%; }
		.bal4 {
			left: -10%; }
		.bal5 {
			left: -10%; }
		.bal6 {
			left: -10%;
			top: 78%; }
		.bal7 {
			left: -10%; }
		.bal8 {
			right: -15%;
			top: 20%; }
		.bal9 {
			right: -15%; }
		.bal10 {
			right: -15%; } }
	.window-content  .open-type {
		display: none; } }



/* Custom, iPhone Retina */
@media only screen and (max-width : 380px) {
	/**/
	.header-mnu ul {
		top: 60px; } }

/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/ }

