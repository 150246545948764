@import url(../libs/bootstrap-grid/bootstrap-grid.css);

@import url(../libs/font-awesome/css/font-awesome.min.css);

@import url(../libs/magnific-popup/magnific-popup.css);

@import url(../libs/slick_work/slick.css);

@import url(../libs/slick_work/slick-theme.css);

@import url(../fonts/icomoon/style.css);

@import url(../libs/jquery-ui-1.12.1.custom/jquery-ui.min.css);

@font-face {
  font-family: "akrobat";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/akrobat/Akrobat.eot");
  src: url("../fonts/akrobat/Akrobat.eot?#iefix") format("embedded-opentype"), url("../fonts/akrobat/Akrobat.woff") format("woff"), url("../fonts/akrobat/Akrobat.ttf") format("truetype");
}

@font-face {
  font-family: "akrobatBold";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/akrobat/Akrobatbold.eot");
  src: url("../fonts/akrobat/Akrobatbold.eot?#iefix") format("embedded-opentype"), url("../fonts/akrobat/Akrobatbold.woff") format("woff"), url("../fonts/akrobat/Akrobatbold.ttf") format("truetype");
}

@font-face {
  font-family: "roboto-example";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/RobotoBold.eot");
  src: url("../fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/RobotoBold.woff") format("woff"), url("../fonts/RobotoBold/RobotoBold.ttf") format("truetype");
}

@font-face {
  font-family: "fontawesome";
  font-weight: normal;
  font-style: normal;
  src: url("../libs/font-awesome/fonts/fontawesome-webfont.eot");
  src: url("../libs/font-awesome/fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../libs/font-awesome/fonts/fontawesome-webfont.woff") format("woff"), url("../libs/font-awesome/fonts/fontawesome-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "iconmoon";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/icomoon/fonts/icomoon.eot");
  src: url("../fonts/icomoon/fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.woff") format("woff"), url("../fonts/icomoon/fonts/icomoon.ttf") format("truetype");
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

input,
textarea,
select {
  outline: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

a:active,
a:focus {
  outline: none;
}

.slick-slide {
  outline: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

*:-moz-placeholder {
  color: #666;
  opacity: 1;
}

*::-moz-placeholder {
  color: #666;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  color: #666;
}

body input:required:valid,
body textarea:required:valid {
  color: #666;
}

html,
body {
  height: 100%;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.6;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  overflow-x: hidden;
  color: #000;
}

img {
  max-width: 100%;
}

.hidden {
  display: none;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

a {
  transition: all 0.3s;
  color: #000;
}

a:hover {
  opacity: .75;
  text-decoration: none;
}

img,
input,
textarea {
  max-width: 100%;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.button-block {
  text-align: center;
  margin: 15px 0;
}

.button-block .button {
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 5px;
}

.button-block .button.accent {
  background-color: #f7b70b;
  color: #fff;
  font-weight: bold;
}

.strong {
  font-weight: bold;
}

h2,
.h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
}

h2 span,
.h2 span {
  color: #f7b70b;
}

h3 {
  font-weight: 700;
  font-size: 18px;
}

section {
  padding: 50px 0 30px;
}

section h2 {
  text-align: center;
  line-height: 35px;
  margin-bottom: 30px;
}

.wrap-items {
  display: flex;
}

header {
  height: 100vh;
}

.ishome .wrap-header {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: 50%;
}

.ishome .wrap-header:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.header-mnu {
  box-shadow: 0 0 3px #b5b5b5;
  position: relative;
  background: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
}

.header-mnu ul li {
  display: inline-block;
}

.header-mnu ul li.active a {
  color: #f7b70b;
}

.header-mnu ul a {
  padding: 4px 10px;
  text-transform: uppercase;
}

.header-mnu ul a:hover {
  color: #f7b70b;
}

.header-mnu .wrap-items {
  align-items: center;
  min-height: 70px;
}

.logo {
  min-width: 150px;
  margin: 0 auto 0 0;
}

.logo .img-block {
  height: 100%;
}

.logo img {
  max-width: 200px;
  vertical-align: middle;
}

.header-phone {
  font-size: 18px;
  margin-left: 20px;
  -webkit-white-space: nowrap;
  -moz-white-space: nowrap;
  -ms-white-space: nowrap;
  -o-white-space: nowrap;
  white-space: nowrap;
}

.header-content {
  margin: auto 0 auto 0;
  position: relative;
  color: #fff;
}

.header-content h2 {
  background-color: rgba(0, 0, 0, 0.2);
  display: inline-block;
  padding: 5px 10px;
}

.header-content p {
  margin: 15px 0;
  font-size: 24px;
}

.header-content .wrap-content {
  max-width: 650px;
}

.header-content .button-block {
  margin-top: 25px;
  text-align: left;
}

.header-content .button {
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}

.s-window-tabs {
  padding-bottom: 0;
}

.s-price-windows {
  padding-top: 0;
}

.s-window-tabs,
.s-price-windows {
  font-size: 14px;
}

.s-window-tabs .tabs li,
.s-price-windows .tabs li {
  display: inline-block;
  padding: 8px 10px;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #fff;
  border-left: 2px solid transparent;
  border-right: 2px solid  transparent;
  font-size: 15px;
}

.s-window-tabs .tabs li p,
.s-price-windows .tabs li p {
  border-bottom: 1px dotted #f7b70b;
}

.s-window-tabs .tabs li.active,
.s-price-windows .tabs li.active {
  border-top: 2px solid #f7b70b;
  border-left: 2px solid #f7b70b;
  border-right: 2px solid #f7b70b;
  border-radius: 5px 5px 0 0;
  color: #f7b70b;
  border-bottom: 4px solid #fff;
  position: relative;
  top: 1px;
}

.s-window-tabs .tabs li.active p,
.s-price-windows .tabs li.active p {
  border-bottom: none;
}

.s-window-tabs .tabs-cont,
.s-price-windows .tabs-cont {
  padding: 10px;
  border: 2px solid #f7b70b;
  border-radius: 0 5px 5px 5px;
  margin-top: -2px;
  margin-bottom: 45px;
  z-index: 1;
}

.s-window-tabs .tabs-cont h4,
.s-price-windows .tabs-cont h4 {
  font-size: 22px;
  color: #f7b70b;
  font-weight: bold;
  display: inline-block;
  text-decoration: underline;
}

.s-window-tabs .tabs-cont h6,
.s-price-windows .tabs-cont h6 {
  font-weight: bold;
  margin: 15px 0;
}

.s-window-tabs .garanty-block,
.s-price-windows .garanty-block {
  display: flex;
  margin: 15px 0;
}

.s-window-tabs .left-item,
.s-price-windows .left-item {
  flex-basis: 50%;
  display: flex;
}

.s-window-tabs .left-item img,
.s-price-windows .left-item img {
  min-width: 81px;
}

.s-window-tabs .right-item,
.s-price-windows .right-item {
  flex-basis: 50%;
  display: flex;
}

.s-window-tabs .right-item img,
.s-price-windows .right-item img {
  min-width: 100px;
  height: 70px;
}

.s-window-tabs .rassr,
.s-price-windows .rassr {
  color: #f7b70b;
  text-decoration: underline;
  margin-top: 10px;
}

.s-window-tabs .left-imgs ul,
.s-price-windows .left-imgs ul {
  width: 161px;
  float: left;
  margin-top: 10px;
}

.s-window-tabs .left-imgs li,
.s-price-windows .left-imgs li {
  text-align: left;
  padding: 7px 10px;
  background-color: #edf4f5;
  border-radius: 5px;
  width: 100%;
  float: right;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  line-height: 20px;
  font-weight: bold;
  font-size: 15px;
}

.s-window-tabs .left-imgs li:after,
.s-price-windows .left-imgs li:after {
  content: '';
  margin-top: -8px;
  position: absolute;
  left: -18px;
  top: 50%;
  border: 8px solid transparent;
  border-right: 10px solid #edf4f5;
  z-index: 3;
}

.s-window-tabs .window_image,
.s-price-windows .window_image {
  float: left;
  padding-left: 20px;
  padding-right: 30px;
  text-align: center;
}

.s-window-tabs .before_window_image,
.s-price-windows .before_window_image {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 15px;
}

.s-window-tabs span.price,
.s-price-windows span.price {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.s-window-tabs .b_window_price,
.s-price-windows .b_window_price {
  color: #f7b70b;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}

.s-window-tabs .tab-item:not(.active),
.s-price-windows .tab-item:not(.active) {
  display: none;
}

.s-price-windows .tabs ul {
  text-align: center;
}

.s-price-windows .window_type {
  padding-top: 10px;
  padding-bottom: 28px;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}

.s-price-windows .price {
  color: #f7b70b;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.s-price-windows .price .pr_small {
  font-weight: normal;
  font-size: 16px;
}

.s-price-windows .price_window_image {
  height: 275px;
  text-align: center;
}

.s-price-windows .price_window_image img {
  margin: auto;
}

.s-price-windows .wind_item {
  display: flex;
  justify-content: space-between;
}

.s-price-windows .price-win {
  width: 84%;
  margin: 0 auto;
}

.s-price-windows .char_item {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.s-price-windows .char_item:before {
  content: ".............................................................";
  position: absolute;
  height: 0;
  left: 0;
  top: 0;
}

.s-price-windows .c_name {
  position: relative;
  background-color: #fff;
}

.s-price-windows .c_value {
  position: relative;
  background-color: #fff;
}

.s-price-windows .score_graph_item {
  text-align: left;
  border-radius: 7px;
  border: 1px solid #c0cbcd;
  line-height: 16px;
  padding-left: 3px;
  padding-right: 1px;
  height: 18px;
}

.tabs-cont-window .button-block {
  margin-top: 40px;
  margin-bottom: 30px;
}

.tabs-cont-window .line {
  width: 50px;
  height: 8px;
  border-radius: 4px;
  background: #f7b70b;
  display: inline-block;
}

.s-small-images .left-images {
  flex-basis: 300px;
}

.s-small-images .wrap-items {
  justify-content: space-between;
  margin: 30px 0;
}

.s-small-images .right-images {
  flex-basis: 300px;
}

.s-small-images .left-images .item {
  justify-content: flex-end;
}

.s-small-images .item {
  display: flex;
  align-items: center;
}

.s-small-images .item .img-bkock {
  width: 96px;
  min-width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;
  margin: 15px;
}

.s-small-images h5 {
  font-size: 18px;
  font-weight: bold;
  color: #f7b70b;
}

.s-small-images .small-slider {
  display: none;
}

.s-small-images .small-slider .item {
  flex-direction: column;
}

.profiles .item {
  border-radius: 5px;
  border: 2px solid #e1ecee;
  box-sizing: border-box;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
}

.s-pic-balcony {
  line-height: 1.2;
}

.s-pic-balcony .balcon-des {
  position: absolute;
  max-width: 150px;
}

.s-pic-balcony .img-block {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.s-pic-balcony .descr {
  font-size: 12px;
}

.s-pic-balcony .bal1 {
  top: 160px;
  left: -100px;
}

.s-pic-balcony .bal2 {
  top: 220px;
  left: -100px;
}

.s-pic-balcony .bal3 {
  top: 300px;
  left: 25px;
}

.s-pic-balcony .bal4 {
  top: 410px;
  left: -100px;
}

.s-pic-balcony .bal5 {
  top: 530px;
  left: -100px;
}

.s-pic-balcony .bal6 {
  top: 630px;
  left: -50px;
}

.s-pic-balcony .bal7 {
  top: 680px;
  left: -50px;
}

.s-pic-balcony .bal8 {
  top: 290px;
  right: -100px;
}

.s-pic-balcony .bal9 {
  top: 480px;
  right: -100px;
}

.s-pic-balcony .bal10 {
  top: 675px;
  right: -100px;
}

.big-buttons {
  margin-bottom: 30px;
}

.big-buttons a {
  display: inline-block;
  width: 49%;
  height: 50px;
  white-space: nowrap;
}

.s-bottom-form {
  background: url(../img/glazing.jpg) 50%;
  background-size: cover;
  position: relative;
  padding: 100px 0;
}

.s-bottom-form:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.s-bottom-form .wrap-items {
  align-items: center;
}

.s-bottom-form .content-block {
  color: #fff;
}

.s-bottom-form .content-block .descr {
  font-size: 24px;
  font-weight: bold;
}

.s-bottom-form .container {
  position: relative;
}

.s-bottom-form .form-block {
  flex-basis: 50%;
  padding: 0 15px;
}

.s-bottom-form .content-block {
  flex-basis: 50%;
  padding: 0 15px;
}

.s-bottom-form h2 {
  font-size: 48px;
  line-height: 50px;
  text-align: left;
}

form.callBack,
#callBack {
  background-color: #fff;
  padding: 30px 30px;
  border-radius: 8px;
}

form.callBack label,
#callBack label {
  display: block;
  width: 100%;
  margin: 15px 0;
}

form.callBack label span,
#callBack label span {
  display: block;
}

form.callBack label span.error,
#callBack label span.error {
  color: red;
  font-size: 14px;
  display: none;
}

form.callBack input,
#callBack input {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  text-indent: 10px;
  border: 1px solid #ccc;
}

form.callBack input[type=checkbox],
#callBack input[type=checkbox] {
  width: 16px;
  height: 16px;
  min-width: 16px;
}

form.callBack button,
#callBack button {
  border: none;
}

form.callBack .agree,
#callBack .agree {
  display: flex;
}

form.callBack .agree-text,
#callBack .agree-text {
  font-size: 10px;
  margin-left: 10px;
}

.brands-partners {
  background-color: #33333f;
  padding: 20px 0;
}

.brands-partners .item {
  margin: 0 10px;
  text-align: center;
}

.brands-partners .item img {
  max-height: 100px;
  display: inline-block;
}

.contact-block {
  text-align: center;
  padding: 40px 20px;
}

.contact-block .item {
  flex-basis: 33%;
  border-right: 1px solid #f7b70b;
}

.contact-block .item:last-child {
  border-right: none;
}

.contact-block h5 {
  color: #a7a7a7;
  margin-bottom: 5px;
}

.contact-block a,
.contact-block p {
  color: #f7b70b;
  font-weight: bold;
}

.contact-block .img-block {
  margin-bottom: 20px;
}

.contact-block .img-block span {
  font-size: 40px;
}

.contact-block .img-block span:before {
  color: #f7b70b;
}

.prev-works {
  margin: 40px 0;
}

.prev-works .wrap-items {
  justify-content: space-between;
}

.prev-works .item {
  flex-basis: 33%;
  padding: 15px 5px;
  color: #fff;
}

.prev-works .wrap-item {
  position: relative;
  max-height: 205px;
  overflow: hidden;
}

.prev-works .lay-block {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
}

.prev-works .lay-block h4 {
  text-transform: uppercase;
  padding-left: 10px;
  margin: 10px 0;
}

.prev-works .lay-block p {
  color: #bbb;
  padding-left: 10px;
  font-size: 14px;
}

.prev-works .button-block {
  margin: auto 0 30px;
}

.prev-works .button-block .button.accent {
  font-size: 10px;
}

strong {
  font-weight: bold;
}

.our-advantages {
  background: #f7b70b;
  color: #fff;
  padding: 50px 0;
  text-align: center;
}

.our-advantages .title {
  font-size: 40px;
  margin: 30px 0 0;
}

.our-advantages .descr {
  text-transform: uppercase;
  font-weight: bold;
}

.our-advantages span[class^="icon"] {
  font-size: 40px;
}

.our-advantages span[class^="icon"]:before {
  color: #fff;
}

.our-advantages .item {
  padding: 0 15px;
  flex-basis: 25%;
}

.descr-advantages {
  padding-top: 40px;
}

.descr-advantages .wrap-items {
  flex-wrap: wrap;
}

.descr-advantages .item {
  flex-basis: 50%;
  padding: 0 15px 40px;
}

.descr-advantages h3 {
  font-weight: bold;
  font-size: 18px;
}

.descr-advantages .wrap-item {
  display: flex;
}

.descr-advantages .img-block {
  flex-basis: 75px;
  min-width: 75px;
  font-size: 36px;
  padding-top: 30px;
}

.descr-advantages .img-block span:before {
  color: #f7b70b;
}

.s-type-balcony .wrap-items {
  justify-content: space-between;
}

.s-type-balcony .item {
  flex-basis: 33%;
  text-align: center;
}

.s-type-balcony .descr {
  color: #A9A9A9;
  font-size: 14px;
}

.s-type-balcony .img-block {
  margin: 15px 0;
}

.s-type-balcony .name-profile {
  color: #f7b70b;
  font-weight: bold;
}

.s-type-balcony .price-profile {
  margin-top: 15px;
}

.s-type-balcony .price-profile span {
  font-size: 22px;
  font-weight: bold;
  color: #f7b70b;
  display: block;
  line-height: 25px;
}

.s-part-balcony h5,
.small-slider h5 {
  color: #f7b70b;
  max-width: 145px;
  margin: 10px auto;
}

.s-part-balcony .item,
.small-slider .item {
  text-align: center;
}

.s-part-balcony .item img,
.small-slider .item img {
  max-height: 100px;
  display: inline-block;
}

.s-part-balcony .slick-next,
.small-slider .slick-next {
  top: 36%;
  right: 10px;
  z-index: 99;
}

.s-part-balcony .slick-next:before,
.small-slider .slick-next:before {
  color: #f7b70b;
  font-size: 30px;
}

.s-part-balcony .slick-prev,
.small-slider .slick-prev {
  top: 36%;
  left: 10px;
  z-index: 99;
}

.s-part-balcony .slick-prev:before,
.small-slider .slick-prev:before {
  color: #f7b70b;
  font-size: 30px;
}

.call-master {
  background: #f7b70b;
  font-size: 24px;
  color: #fff;
}

.call-master .form-block {
  padding-top: 30px;
  padding-left: 15px;
  max-width: 580px;
}

.call-master strong {
  color: #000;
}

.call-master form {
  background-color: #f7b70b;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
}

.call-master form button,
.call-master form input {
  width: 100%;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  height: 46px;
  display: inline-block;
}

.call-master button {
  transition: all .3s;
}

.call-master button:hover {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.call-master label {
  min-width: 48%;
  flex-basis: 48%;
}

.call-master .button-block {
  min-width: 48%;
  flex-basis: 48%;
}

.popup-block {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

#callBack {
  max-width: 600px;
  margin: auto;
  position: relative;
}

.wrap-pop {
  display: none;
}

.mod-close {
  font-size: 30px;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

.s-reg .wrap-image {
  position: relative;
  width: 550px;
  margin: 50px auto 30px;
}

.s-reg .descr {
  font-size: 12px;
}

.s-reg .reg1 {
  position: absolute;
  top: -10px;
  left: -100px;
}

.s-reg .reg2 {
  position: absolute;
  top: 50px;
  left: -115px;
}

.s-reg .reg3 {
  position: absolute;
  top: 200px;
  left: -100px;
}

.s-reg .reg4 {
  position: absolute;
  top: 0;
  right: -145px;
}

.s-reg .reg5 {
  position: absolute;
  top: 200px;
  right: -135px;
}

footer {
  background-color: #3b3b3b;
  color: #fff;
  text-align: center;
  padding: 30px 0;
}

.mobil-mnu {
  background-color: #fff;
  color: #000;
  display: none;
}

.toggle-mnu {
  display: block;
  width: 28px;
  height: 28px;
}

.toggle-mnu span:after,
.toggle-mnu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
}

.toggle-mnu span:after {
  top: 18px;
}

.toggle-mnu span {
  position: relative;
  display: block;
}

.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px;
}

.toggle-mnu.on span {
  background-color: transparent;
}

.toggle-mnu.on span:before {
  transform: rotate(45deg) translate(-1px, 0px);
}

.toggle-mnu.on span:after {
  transform: rotate(-45deg) translate(6px, -7px);
}

.wrap-calc {
  max-width: 1140px;
  padding: 60px 0;
  margin: 0 auto;
  font-size: 14px;
}

.wrap-calc h2 {
  text-align: center;
  margin-bottom: 30px;
}

.wrap-calc .preview-images {
  text-align: center;
}

.wrap-calc .preview-images label {
  border: 1px solid #000;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
}

.wrap-calc .preview-images label:hover {
  background: #FFE9A1;
}

.wrap-calc .preview-images label.active {
  background: #f7b70b;
}

.wrap-calc .preview-images label input {
  display: none;
}

.wrap-calc .big-pic {
  position: relative;
}

.wrap-calc .output-item {
  position: relative;
  display: none;
}

.wrap-calc .output-item.show-item {
  display: inline-block;
}

.wrap-calc .dinamic-block {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.wrap-calc .open-type {
  position: absolute;
  top: 45px;
  left: 40px;
  width: 107px;
  background-color: #F3F9FC;
}

.wrap-calc .open-type label {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  min-height: 20px;
  text-transform: uppercase;
  font-size: 10px;
  padding: 5px;
}

.wrap-calc .open-type label span {
  margin-left: 10px;
  font-weight: bold;
  line-height: 1;
}

.wrap-calc .two-window .open-type-left {
  left: auto;
  right: 40px;
}

.wrap-calc .wrap-items {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.wrap-calc .button {
  display: inline-block;
  color: #fff;
  background-color: #f7b70b;
  padding: 8px 40px;
  text-align: center;
  min-width: 200px;
  border-radius: 15px;
  cursor: pointer;
  border: none;
}

.wrap-calc .button:hover {
  opacity: .8;
}

.wrap-calc .but-first {
  text-align: center;
  margin-bottom: 20px;
}

.wrap-calc .wrap-slider {
  position: relative;
}

.wrap-calc .prev-balcons img {
  min-height: 42px;
}

.wrap-calc .second-slide {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.wrap-calc .second-slide .descr {
  font-size: 20px;
  font-weight: bold;
  margin: 15px 0;
}

.wrap-calc .work-items {
  display: inline-block;
  text-align: left;
}

.wrap-calc .wrap-works label {
  display: block;
  padding: 5px 0;
  cursor: pointer;
  font-size: 16px;
}

.wrap-calc .open-type label:hover,
.wrap-calc .open-type label.active {
  background: #f7b70b;
}

.big-pic {
  position: relative;
}

.big-pic .dinamic-pic {
  position: absolute;
  top: 15px;
  left: 13px;
  transition: all 1s;
  display: none;
}

.two-window .dinamic-block-left .dinamic-pic {
  left: auto;
  right: 13px;
}

.twoTop-window .dinamic-block-right .dinamic-pic {
  left: 10px;
  top: 120px;
}

.twoTop-window .dinamic-block-right .open-type {
  top: 145px;
  left: 38px;
}

.twoTop-window .dinamic-block-left .dinamic-pic {
  left: auto;
  right: 10px;
  top: 120px;
}

.twoTop-window .dinamic-block-left .open-type {
  top: 145px;
  left: auto;
  right: 38px;
}

.third-window .dinamic-block-left .dinamic-pic {
  left: auto;
  right: 12px;
}

.third-window .dinamic-block-left .open-type {
  left: auto;
  right: 40px;
}

.third-window .dinamic-block-right .dinamic-pic {
  left: 10px;
}

.third-window .dinamic-block-center .dinamic-pic {
  left: 175px;
}

.third-window .dinamic-block-center .open-type {
  left: 202px;
}

.balcon .window-content {
  display: none;
}

.balcon .balkony-content {
  display: block;
}

.big-window .dinamic-block-right .dinamic-pic {
  left: 10px;
}

.big-window .dinamic-block-left .dinamic-pic {
  left: 175px;
}

.big-window .dinamic-block-left .open-type {
  left: 200px;
}

.y-slider {
  display: inline-block;
  margin-right: 30px;
}

.y-slider .ui-widget.ui-widget-content {
  height: 300px;
  background: url(../img/scale_v.png) repeat-y;
}

.y-slider .ui-widget-header {
  background: url(../img/scale_v.png) repeat-y;
}

.y-start {
  position: absolute;
  bottom: -18px;
  left: -7px;
  font-size: 14px;
}

.y-end {
  position: absolute;
  top: -18px;
  left: -7px;
  font-size: 14px;
}

.x-start {
  position: absolute;
  top: -3px;
  left: -32px;
  font-size: 14px;
}

.x-end {
  position: absolute;
  top: -3px;
  right: -32px;
  font-size: 14px;
}

.x-slider {
  min-width: 600px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.x-slider .ui-widget.ui-widget-content {
  background: url(../img/scale_h.png) repeat-x;
}

.x-slider .ui-widget-header {
  background: url(../img/scale_h.png) repeat-x;
}

.wrap-output {
  margin-top: 40px;
}

.wrap-calc #amount,
.wrap-calc #amount2,
#balcone-width,
#balcone-height {
  display: none;
}

.slider-block {
  position: relative;
}

.gabarites {
  margin-right: 30px;
}

.gabarites p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.gabarites label {
  position: relative;
  display: block;
}

.gabarites label:before {
  content: "mm";
  position: absolute;
  right: -20px;
  top: 20px;
  font-size: 14px;
}

.gabarites label span {
  display: block;
}

.gabarites input {
  max-width: 60px;
  text-indent: 10px;
  height: 30px;
}

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.ps1 td:nth-child(1) {
  font-size: 17px;
  color: #949494;
}

.ps5 td:nth-child(1) {
  font-size: 17px;
  color: #dcc004;
}

.ps2 td:nth-child(1) {
  font-size: 17px;
  color: #00a2f0;
}

.ps3 td:nth-child(1) {
  font-size: 17px;
  color: #d4532f;
}

.ps4 td:nth-child(1) {
  font-size: 17px;
  color: #de9000;
}

.calc_window_profile_selector {
  position: relative;
}

.calc_window_profile_selector table {
  width: 220px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.calc_window_profile_selector table td {
  padding: 10px 0px 17px 19px;
  white-space: nowrap;
  cursor: pointer;
}

.calc_window_profile_selector table td p {
  white-space: normal;
}

.calc_window_profile_selector .profile {
  cursor: pointer;
}

.calc_window_profile_selector .profile img {
  margin: -17px 5px -23px -20px;
}

.calc_window_profile_selector td {
  border-top: 1px solid #ddd;
  font-weight: 600;
}

.calc_window_profile_selector td a {
  font-weight: normal;
  font-size: 14px;
  color: #959595;
}

.ps1 .active,
.ps1 .profile:hover,
.ps1 .profile_b:hover {
  background: #9b9b9b;
  background-image: linear-gradient(#c0c0c0 0%, #9b9b9b 100%);
  background-position: 0px -10px;
  background-repeat: no-repeat;
}

.profile,
.profile_b {
  height: 25px;
  width: 320px;
  min-width: 110px;
}

.calc_window_profile_selector .profile:hover a,
.calc_window_profile_selector .profile_b:hover a,
.calc_window_profile_selector .active a {
  color: #fff;
}

.ps5 .active,
.ps5 .profile:hover,
.ps5 .profile_b:hover {
  background: #caca3f;
  background-image: linear-gradient(#d7d954 0%, #c4ca3f 100%);
  background-position: 0px -10px;
  background-repeat: no-repeat;
}

.ps2 .active,
.ps2 .profile:hover,
.ps2 .profile_b:hover {
  background: #3b93bf;
  background-image: linear-gradient(#00a6f6 0%, #3b93bf 100%);
  background-position: 0px -10px;
  background-repeat: no-repeat;
}

.ps3 .active,
.ps3 .profile:hover,
.ps3 .profile_b:hover {
  background: #bf552c;
  background-image: linear-gradient(#d76433 0%, #bf552c 100%);
  background-position: 0px -10px;
  background-repeat: no-repeat;
}

.ps4 .active,
.ps4 .profile:hover,
.ps4 .profile_b:hover {
  background: #caa03f;
  background-image: linear-gradient(#dab155 0%, #caa03f 100%);
  background-position: 0px -10px;
  background-repeat: no-repeat;
}

.calc_window_profile_info {
  padding: 10px;
  width: 290px;
  border: 4px solid #eee;
  background: #fff;
  z-index: 10000;
  border: 1px solid #ddd;
  position: absolute;
  left: -290px;
  top: 0;
  display: none;
}

.calc_window_profile_info h4 {
  font-size: 22px;
  display: block;
  margin: 10px 5px;
}

.calc_window_profile_info li {
  margin-left: 15px;
}

.calc_window_profile_info .profile_img_container {
  flex-basis: 100px;
  min-width: 100px;
  margin-right: 10px;
}

.calc_window_profile_info .profile_img_container img {
  margin: 0;
}

.calc_window_profile_info .progress {
  padding: 3px;
  margin-bottom: 8px;
  background: #eee;
  border-radius: 5px;
  color: #fff;
}

.calc_window_profile_info .progress div {
  padding: 1px;
  text-align: left;
  padding-left: 10px;
  border-radius: 3px;
}

.calc_window_profile_info .wrap-top {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.calc_window_profile_info .progress-block {
  flex-grow: 1;
}

.calc_window_profile {
  display: inline-block;
  text-align: left;
}

.wrap-kharakteristik {
  flex-grow: 1;
  text-align: right;
}

.profile:hover .calc_window_profile_info {
  display: block;
}

#param1 {
  background-color: orange;
}

#param2 {
  background-color: green;
}

#param3 {
  background-color: blue;
}

.slide:not(.slide-1) {
  display: none;
}

.wrap-form {
  display: none;
  width: 600px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #eee;
}

.wrap-form label {
  display: block;
  margin: 15px 0;
}

.wrap-form label span {
  display: block;
}

.wrap-form input {
  width: 100%;
  display: block;
  height: 40px;
  text-indent: 10px;
}

.button-block-next {
  margin-top: 0;
  text-align: right;
}

.button-block-next .button {
  position: relative;
  top: -50px;
  width: 220px;
  border: 1px solid #000;
}

.balkony-content {
  display: none;
}

.balkony-content .preview-images img {
  min-height: 42px;
}

.balkony-content .big-pic img[data-num="2"] {
  display: none;
}

.balkony-content .x-slider {
  bottom: -40px;
}

.balkony-content .wrap-dropdown {
  display: none;
}

.balkony-content .wrap-dropdown label {
  display: block;
  padding-left: 20px;
}

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */

/* Medium Devices, Desktops */

/* Small Devices, Tablets */

/* Extra Small Devices, Phones */

/* Custom, iPhone Retina */

/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */

/* Extra Small Devices, Phones */

/* Small Devices, Tablets */

/* Medium Devices, Desktops */

/* Large Devices, Wide Screens */

@media only screen and (min-width: 320px) {
  /**/
}

@media only screen and (min-width: 480px) {
  /**/
}

@media only screen and (min-width: 768px) {
  /**/
}

@media only screen and (min-width: 992px) {
  /**/
}

@media only screen and (min-width: 1200px) {
  /**/
}

@media only screen and (max-width: 1200px) {
  /**/

  .s-window-tabs .tabs li,
  .s-price-windows .tabs li {
    padding: 10px 10px;
    font-size: 14px;
  }

  .s-price-windows .price-win {
    width: 100%;
  }

  .s-price-windows .window_type {
    font-size: 13px;
  }

  .profiles .item {
    padding-left: 10px;
    padding-right: 10px;
  }

  .prev-works .wrap-items {
    flex-wrap: wrap;
    justify-content: center;
  }

  .prev-works .item {
    flex-basis: 50%;
  }

  .wrap-calc {
    padding: 15px;
  }

  .wrap-calc {
    width: 970px;
  }

  .s-part-balcony .slick-next {
    top: 23%;
  }

  .s-part-balcony .slick-prev {
    top: 23%;
  }
}

@media only screen and (max-width: 992px) {
  /**/

  .our-advantages .wrap-items {
    flex-wrap: wrap;
  }

  .our-advantages .item {
    flex-basis: 50%;
    margin: 15px 0;
  }

  .call-master {
    font-size: 18px;
  }

  .call-master .img-block {
    max-width: 300px;
  }

  .wrap-calc {
    max-width: 750px;
  }

  .wrap-calc .wrap-items {
    flex-wrap: wrap;
  }

  .wrap-calc .gabarites {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 15px;
    margin-left: 80px;
  }

  .wrap-calc .gabarites input {
    max-width: 90px;
  }

  .wrap-calc .height-win {
    display: inline-block;
    width: 100px;
    margin-right: 20px;
  }

  .wrap-calc .width-win {
    display: inline-block;
    width: 100px;
  }

  .wrap-calc .wrap-output {
    margin-top: 20px;
  }

  .mobil-mnu {
    display: block;
  }

  .header-mnu ul {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 0;
    top: 90px;
    padding-top: 15px;
    padding-bottom: 40px;
    display: none;
  }

  .header-mnu ul li {
    display: block;
  }

  .header-mnu ul a {
    display: block;
    padding: 10px 15px;
    font-weight: bold;
  }

  .header-phone {
    margin-right: 20px;
  }

  .wrap-kharakteristik {
    flex: 0 0 100%;
  }

  .calc_window_profile {
    margin: 50px auto 0;
    max-width: 290px;
    display: block;
  }

  .x-start {
    top: 15px;
    left: 0px;
  }

  .x-end {
    top: 15px;
    right: 0px;
  }

  .s-small-images .left-images .item {
    justify-content: space-between;
  }

  .s-small-images .item .img-bkock {
    width: 70px;
    min-width: 70px;
    height: 70px;
  }

  .s-reg .reg-block {
    max-width: 125px;
  }

  .s-reg .title {
    line-height: 15px;
  }

  .s-reg .wrap-image {
    max-width: 445px;
  }

  .s-reg .descr {
    line-height: 1.2;
  }

  .s-reg .reg3 {
    position: absolute;
    top: 170px;
    left: -70px;
  }

  .s-reg .reg5 {
    position: absolute;
    top: 140px;
    right: -90px;
  }

  .s-pic-balcony .img-block {
    max-width: 520px;
  }

  .s-pic-balcony .balcon-des {
    max-width: 130px;
    font-size: 14px;
  }

  .s-pic-balcony .bal1 {
    top: 15%;
  }

  .s-pic-balcony .bal2 {
    top: 22%;
  }

  .s-pic-balcony .bal3 {
    top: 37%;
  }

  .s-pic-balcony .bal4 {
    top: 57%;
  }

  .s-pic-balcony .bal5 {
    top: 68%;
  }

  .s-pic-balcony .bal6 {
    top: 76%;
  }

  .s-pic-balcony .bal7 {
    top: 85%;
  }

  .s-pic-balcony .bal8 {
    top: 35%;
  }

  .s-pic-balcony .bal9 {
    top: 60%;
  }

  .s-pic-balcony .bal10 {
    top: 86%;
  }

  .s-part-balcony .slick-next {
    right: 0;
  }

  .s-part-balcony .slick-prev {
    left: -15px;
  }
}

@media only screen and (max-width: 768px) {
  /**/

  .s-window-tabs .right-item {
    display: block;
    padding-left: 5px;
  }

  .s-window-tabs .rassr {
    display: block;
  }

  .s-window-tabs .left-item {
    flex-basis: 100%;
  }

  .s-window-tabs .right-item img {
    margin-left: -20px;
  }

  .s-window-tabs .tabs li {
    padding: 10px 5px;
    font-size: 12px;
    text-align: center;
  }

  .s-window-tabs .tabs li.active {
    border-bottom: 2px solid #f7b70b;
  }

  .header-content .wrap-content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .h2,
  h2 {
    font-size: 25px;
  }

  .header-content p {
    font-size: 18px;
  }

  .tabs-cont-window .line {
    width: 18%;
  }

  .s-bottom-form .wrap-items {
    display: block;
  }

  .s-bottom-form .content-block {
    margin-top: 30px;
  }

  .s-bottom-form .content-block h2 {
    font-size: 30px;
    text-align: center;
  }

  .s-bottom-form .content-block .descr {
    font-size: 18px;
    text-align: center;
  }

  .contact-block .wrap-items {
    flex-wrap: wrap;
    justify-content: center;
  }

  .contact-block .item {
    flex-basis: 50%;
    padding: 15px 0;
  }

  .prev-works .item {
    flex-basis: 100%;
  }

  body {
    font-size: 14px;
  }

  .descr-advantages .item {
    flex-basis: 100%;
  }

  .s-type-balcony .wrap-items {
    flex-wrap: wrap;
  }

  .s-type-balcony .wrap-items .item {
    flex-basis: 100%;
    margin: 15px 0;
  }

  .our-advantages {
    padding: 30px 0;
  }

  .our-advantages span[class^=icon] {
    font-size: 30px;
  }

  .our-advantages .title {
    font-size: 30px;
    margin-top: 15px;
  }

  .our-advantages .descr {
    font-size: 12px;
  }

  .call-master .img-block {
    display: none;
  }

  .call-master .form-block {
    max-width: 450px;
    margin: auto;
    padding-right: 15px;
  }

  .logo img {
    max-width: 160px;
  }

  .mobil-mnu {
    margin-right: 10px;
  }

  .wrap-calc {
    width: 450px;
  }

  .x-slider {
    min-width: 450px;
    bottom: -25px;
  }

  .wrap-calc .big-pic {
    width: 370px;
  }

  .window-content .two-window .dinamic-block-left .dinamic-pic {
    right: 32px;
  }

  .window-content .two-window .open-type-left {
    right: 60px;
  }

  .window-content .third-window .open-type {
    left: 13px;
  }

  .window-content .third-window .dinamic-block-right .dinamic-pic {
    height: 200px;
  }

  .window-content .third-window .dinamic-block-left .open-type {
    left: auto;
    right: 20px;
  }

  .window-content .third-window .dinamic-pic {
    height: 200px;
  }

  .window-content .third-window .dinamic-block-center .open-type {
    left: 130px;
  }

  .window-content .third-window .dinamic-block-center .dinamic-pic {
    left: 125px;
  }

  .window-content .big-window .dinamic-pic {
    height: 130px;
    top: 8px;
  }

  .window-content .big-window .dinamic-block-left .dinamic-pic {
    left: 135px;
  }

  .window-content .big-window .dinamic-block-left .open-type {
    left: 140px;
  }

  .window-content .big-window .dinamic-block-right .open-type {
    left: 20px;
  }

  .twoTop-window .dinamic-block-left .dinamic-pic {
    right: 35px;
  }

  .twoTop-window .dinamic-block-left .open-type {
    right: 64px;
  }

  .s-small-images {
    padding-left: 15px;
    padding-right: 15px;
  }

  .s-small-images .big-images {
    display: none;
  }

  .s-reg .wrap-image {
    max-width: 75%;
  }

  .s-reg .reg-block {
    max-width: 100px;
  }

  .s-reg {
    padding-bottom: 0;
  }

  .s-reg .reg4 {
    position: absolute;
    top: 26px;
    right: -64px;
  }

  .s-reg .reg5 {
    right: -65px;
  }

  .s-reg .reg1 {
    left: -60px;
  }

  .s-reg .reg2 {
    left: -60px;
  }

  .s-reg .reg3 {
    left: -60px;
  }

  .big-buttons a {
    font-size: 12px;
    height: 40px;
  }

  .s-pic-balcony .img-block {
    max-width: 350px;
  }

  .s-pic-balcony .balcon-des {
    font-size: 12px;
    max-width: 100px;
  }

  .s-pic-balcony .descr {
    font-size: 10px;
  }

  .s-pic-balcony .bal1 {
    left: -10%;
  }

  .s-pic-balcony .bal2 {
    left: -10%;
    top: 24%;
  }

  .s-pic-balcony .bal3 {
    left: -10%;
  }

  .s-pic-balcony .bal4 {
    left: -10%;
  }

  .s-pic-balcony .bal5 {
    left: -10%;
  }

  .s-pic-balcony .bal6 {
    left: -10%;
    top: 78%;
  }

  .s-pic-balcony .bal7 {
    left: -10%;
  }

  .s-pic-balcony .bal8 {
    right: -20%;
  }

  .s-pic-balcony .bal9 {
    right: -20%;
  }

  .s-pic-balcony .bal10 {
    right: -20%;
  }

  .s-part-balcony .wrap-slider {
    margin: 0 25px;
  }

  .profile:hover .calc_window_profile_info {
    display: none;
  }

  .calc_window_profile_selector table {
    width: 100%;
  }

  .button-block-next {
    text-align: center;
  }

  .button-block-next .button {
    top: 0;
    margin-top: 30px;
  }

  .wrap-calc .gabarites {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .wrap-calc .wrap-form {
    width: auto;
  }

  .s-small-images .small-slider {
    display: block;
  }

  .s-small-images .wrap-items {
    display: none;
  }

  .s-small-images .descr {
    display: none;
  }

  .s-small-images .item .img-bkock {
    order: -1;
  }
}

@media only screen and (max-width: 480px) {
  /**/

  .s-window-tabs .left-imgs ul {
    width: 100%;
  }

  .s-window-tabs .garanty-block {
    display: block;
  }

  .s-window-tabs .right-item {
    display: flex;
  }

  .header-content h2 {
    font-size: 25px;
  }

  .h2,
  h2 {
    font-size: 20px;
  }

  .contact-block .item {
    flex-basis: 100%;
    border-right: none;
    border-bottom: 1px solid #f7b70b;
  }

  .prev-works .lay-block {
    width: 100%;
  }

  .descr-advantages .img-block {
    min-width: 55px;
    font-size: 30px;
  }

  .call-master .form-block {
    max-width: 300px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
  }

  .call-master form {
    display: block;
  }

  .header-mnu .wrap-items {
    flex-wrap: wrap;
    min-height: 50px;
  }

  .header-mnu ul {
    top: 50px;
  }

  .header-phone {
    margin-right: 70px;
    font-size: 14px;
  }

  .mobil-mnu {
    position: absolute;
    top: 15px;
    right: 10px;
  }

  .wrap-calc {
    width: 290px;
  }

  .x-slider {
    min-width: 290px;
    bottom: -25px;
  }

  .wrap-calc .big-pic {
    width: 220px;
  }

  .y-slider .ui-widget.ui-widget-content {
    height: 220px;
  }

  .y-slider {
    margin-right: 15px;
  }

  .wrap-calc .but-first .button {
    min-width: 110px;
  }

  .wrap-calc .preview-images label {
    margin: 5px;
  }

  .calc_window_profile_selector table {
    width: 290px;
  }

  .s-small-images .wrap-items {
    flex-wrap: wrap;
  }

  .s-small-images .left-images .item {
    justify-content: flex-start;
  }

  .s-reg {
    padding-bottom: 20px;
  }

  .s-reg .reg-block {
    max-width: 90px;
  }

  .s-reg .reg4 {
    top: 26px;
    right: -50px;
  }

  .s-reg .reg5 {
    right: -50px;
  }

  .s-reg .reg1 {
    left: -15px;
    top: -35px;
  }

  .s-reg .reg2 {
    left: -35px;
    top: 20px;
  }

  .s-reg .reg3 {
    left: -35px;
    top: 115px;
  }

  .s-reg .title {
    font-size: 12px;
  }

  .s-reg .descr {
    font-size: 10px;
  }

  .button-block .button.accent {
    display: block;
    margin: 15px;
    width: auto;
  }

  .s-pic-balcony .img-block {
    max-width: 260px;
  }

  .s-pic-balcony .balcon-des {
    font-size: 10px;
    max-width: 90px;
  }

  .s-pic-balcony .descr {
    font-size: 8px;
  }

  .s-pic-balcony .bal1 {
    left: -10%;
  }

  .s-pic-balcony .bal2 {
    left: -10%;
    top: 24%;
  }

  .s-pic-balcony .bal3 {
    left: -10%;
  }

  .s-pic-balcony .bal4 {
    left: -10%;
  }

  .s-pic-balcony .bal5 {
    left: -10%;
  }

  .s-pic-balcony .bal6 {
    left: -10%;
    top: 78%;
  }

  .s-pic-balcony .bal7 {
    left: -10%;
  }

  .s-pic-balcony .bal8 {
    right: -15%;
    top: 20%;
  }

  .s-pic-balcony .bal9 {
    right: -15%;
  }

  .s-pic-balcony .bal10 {
    right: -15%;
  }

  .window-content .open-type {
    display: none;
  }
}

@media only screen and (max-width: 380px) {
  /**/

  .header-mnu ul {
    top: 60px;
  }
}