@import "fonts";
@import "libs";
@import "vars";
@import "reset";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }
body input:required:valid,
body textarea:required:valid {
	color: #666; }

html, body {
	height: 100%; }

body {
	font-size: 16px;
	min-width: 320px;
	position: relative;
	line-height: 1.6;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	overflow-x: hidden;
	color: #000; }
img {
	max-width: 100%; }
.hidden {
	display: none; }

ul,
li {
	list-style-type: none;
	padding: 0;
	margin: 0; }
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box; }
.clearfix::after {
	content: "";
	display: table;
	clear: both; }
a {
	transition: all 0.3s;
	color: #000;
	&:hover {
		opacity: .75;
		text-decoration: none; } }
img,
input,
textarea {
	max-width: 100%; }
.img-responsive {
	display: block;
	max-width: 100%;
	height: auto; }

.text-center {
	text-align: center; }
.s-wide {
 }	// стили для полной секции

.s-dark {
 }	// стили для темной секции
.flex {
	display: flex;
	flex-wrap: wrap; }
.button-block {
	text-align: center;
	margin: 15px 0;
	.button {
		text-transform: uppercase;
		padding: 10px 25px;
		border-radius: 5px;
		&.accent {
			background-color: $accent;
			color: #fff;
			font-weight: bold; } } }
.strong {
	font-weight: bold; }
h2, .h2 {
	font-size: 30px;
	text-transform: uppercase;
	font-weight: bold;
	span {
		color: $accent; } }
h3 {
	font-weight: 700;
	font-size: 18px; }


section {
	padding: 50px 0 30px;
	h2 {
		text-align: center;
		line-height: 35px;
		margin-bottom: 30px; } }
.wrap-items {
	display: flex; }
header {
	height: 100vh; }
.ishome {
	.wrap-header {
		display: flex;
		flex-direction: column;
		height: 100vh;
		position: relative;
		background-size: cover;
		background-position: 50%;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,.5); } } }
.header-mnu {
	box-shadow: 0 0 3px #b5b5b5;
	position: relative;
	background: #fff;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 100;
	ul {
		li {
			display: inline-block;
			&.active {
				a {
					color: $accent; } } }
		a {
			padding: 4px 10px;
			text-transform: uppercase;

			&:hover {
				color: $accent; } } }

	.wrap-items {
		align-items: center;
		min-height: 70px; } }
.logo {
	min-width: 150px;
	margin: 0 auto 0 0;
	.img-block {
		height: 100%; }
	img {
		max-width: 200px;
		vertical-align: middle; } }
.header-phone {
	font-size: 18px;
	margin-left: 20px;
	-webkit-white-space: nowrap;
	-moz-white-space: nowrap;
	-ms-white-space: nowrap;
	-o-white-space: nowrap;
	white-space: nowrap; }
.header-content {
	margin: auto 0 auto 0;
	position: relative;
	color: #fff;
	h2 {
		background-color: rgba(0,0,0,0.2);
		display: inline-block;
		padding: 5px 10px; }
	p {
		margin: 15px 0;
		font-size: 24px; }
	.wrap-content {
		max-width: 650px; }
	.button-block {
		margin-top: 25px;
		text-align: left; }
	.button {
		color: #fff;
		padding: 10px 20px;
		border-radius: 5px;
		font-size: 14px; } }
.s-window-tabs {
	padding-bottom: 0; }
.s-price-windows {
	padding-top: 0; }
.s-window-tabs, .s-price-windows {
	font-size: 14px;
	.tabs {
		li {
			display: inline-block;
			padding: 8px 10px;
			font-weight: bold;
			cursor: pointer;
			border-top: 2px solid #fff;
			border-left: 2px solid transparent;
			border-right: 2px solid  transparent;
			font-size: 15px;
			p {
				border-bottom: 1px dotted $accent; }
			&.active {
				border-top: 2px solid $accent;
				border-left: 2px solid $accent;
				border-right: 2px solid $accent;
				border-radius: 5px 5px 0 0;
				color: $accent;
				border-bottom: 4px solid #fff;
				position: relative;
				top: 1px;
				p {
					border-bottom: none; } } } }
	.tabs-cont {
		padding: 10px;
		border: 2px solid $accent;
		border-radius: 0 5px 5px 5px;
		margin-top: -2px;
		margin-bottom: 45px;
		z-index: 1;
		h4 {
			font-size: 22px;
			color: $accent;
			font-weight: bold;
			display: inline-block;
			text-decoration: underline; }
		h6 {
			font-weight: bold;
			margin: 15px 0; } }
	.garanty-block {
		display: flex;
		margin: 15px 0; }
	.left-item {
		flex-basis: 50%;
		display: flex;
		img {
			min-width: 81px; } }
	.right-item {
		flex-basis: 50%;
		display: flex;
		img {
			min-width: 100px;
			height: 70px; } }
	.rassr {
		color: $accent;
		text-decoration: underline;
		margin-top: 10px; }
	.left-imgs {
		ul {
			width: 161px;
			float: left;
			margin-top: 10px; }


		li {
			text-align: left;
			padding: 7px 10px;
			background-color: #edf4f5;
			border-radius: 5px;
			width: 100%;
			float: right;
			position: relative;
			display: inline-block;
			margin-bottom: 20px;
			line-height: 20px;
			font-weight: bold;
			font-size: 15px;
			&:after {
				content: '';
				margin-top: -8px;
				position: absolute;
				left: -18px;
				top: 50%;
				border: 8px solid transparent;
				border-right: 10px solid #edf4f5;
				z-index: 3; } } }


	.window_image {
		float: left;
		padding-left: 20px;
		padding-right: 30px;
		text-align: center; }
	.before_window_image {
		font-size: 14px;
		font-weight: bold;
		text-align: center;
		padding-bottom: 15px; }
	span {
		&.price {
			font-size: 24px;
			font-weight: 700;
			text-transform: uppercase; } }
	.b_window_price {
		color: $accent;
		font-size: 18px;
		text-align: center;
		margin-top: 10px; }
	.tab-item {
		&:not(.active) {
			display: none; } } }

.s-price-windows {
	.tabs {
		ul {
			text-align: center; } }
	.window_type {
		padding-top: 10px;
		padding-bottom: 28px;
		font-size: 14px;
		font-weight: 700;
		color: #000000; }
	.price {
		color: $accent;
		font-size: 24px;
		font-weight: 700;
		text-transform: uppercase;
		.pr_small {
			font-weight: normal;
			font-size: 16px; } }
	.price_window_image {
		height: 275px;
		text-align: center;
		img {
			margin: auto; } }
	.wind_item {
		display: flex;
		justify-content: space-between; }
	.price-win {
		width: 84%;
		margin: 0 auto; }
	.char_item {
		display: flex;
		justify-content: space-between;
		position: relative;
		&:before {
			content: ".............................................................";
			position: absolute;
			height: 0;
			left: 0;
			top: 0; } }
	.c_name {
		position: relative;
		background-color: #fff; }
	.c_value {
		position: relative;
		background-color: #fff; }
	.score_graph_item {
		text-align: left;
		border-radius: 7px;
		border: 1px solid #c0cbcd;
		line-height: 16px;
		padding-left: 3px;
		padding-right: 1px;
		height: 18px; } }
.tabs-cont-window {
	.button-block {
		margin-top: 40px;
		margin-bottom: 30px; }

	.line {
		width: 50px;
		height: 8px;
		border-radius: 4px;
		background: $accent;
		display: inline-block; } }

.s-small-images {
	.left-images {
		flex-basis: 300px; }
	.wrap-items {
		justify-content: space-between;
		margin: 30px 0; }
	.right-images {
		flex-basis: 300px; }
	.left-images {
		.item {
			justify-content: flex-end; } }
	.item {
		display: flex;
		align-items: center;
		.img-bkock {
			width: 96px;
			min-width: 96px;
			height: 96px;
			border-radius: 50%;
			overflow: hidden;
			margin: 15px; } }
	h5 {
		font-size: 18px;
		font-weight: bold;
		color: $accent; }
	.small-slider {
		display: none;
		.item {
			flex-direction: column; } } }

.profiles {
	.item {
		border-radius: 5px;
		border: 2px solid #e1ecee;
		box-sizing: border-box;
		text-align: center;
		padding: 15px;
		margin-top: 10px; } }


.s-pic-balcony {
	line-height: 1.2;
	.balcon-des {
		position: absolute;
		max-width: 150px; }
	.img-block {
		position: relative;
		max-width: 600px;
		margin: 0 auto; }
	.descr {
		font-size: 12px; }
	.bal1 {
		top: 160px;
		left: -100px; }
	.bal2 {
		top: 220px;
		left: -100px; }
	.bal3 {
		top: 300px;
		left: 25px; }
	.bal4 {
		top: 410px;
		left: -100px; }
	.bal5 {
		top: 530px;
		left: -100px; }

	.bal6 {
		top: 630px;
		left: -50px; }
	.bal7 {
		top: 680px;
		left: -50px; }
	.bal8 {
		top: 290px;
		right: -100px; }
	.bal9 {
		top: 480px;
		right: -100px; }
	.bal10 {
		top: 675px;
		right: -100px; } }
.big-buttons {
	margin-bottom: 30px;
	a {
		display: inline-block;
		width: 49%;
		height: 50px;
		white-space: nowrap; } }
.s-bottom-form {
	background: url(../img/glazing.jpg) 50%;
	background-size: cover;
	position: relative;
	padding: 100px 0;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.3); }
	.wrap-items {
		align-items: center; }
	.content-block {
		color: #fff;
		.descr {
			font-size: 24px;
			font-weight: bold; } }
	.container {
		position: relative; }
	.form-block {
		flex-basis: 50%;
		padding: 0 15px; }
	.content-block {
		flex-basis: 50%;
		padding: 0 15px; }
	h2 {
		font-size: 48px;
		line-height: 50px;
		text-align: left; } }

form.callBack, #callBack {
	background-color: #fff;
	padding: 30px 30px;
	border-radius: 8px;
	label {
		display: block;
		width: 100%;
		margin: 15px 0;
		span {
			display: block;
			&.error {
				color: red;
				font-size: 14px;
				display: none; } } }
	input {
		height: 40px;
		width: 100%;
		border-radius: 5px;
		text-indent: 10px;
		border: 1px solid #ccc; }
	input[type=checkbox] {
		width: 16px;
		height: 16px;
		min-width: 16px; }

	button {
		border: none; }
	.agree {
		display: flex; }
	.agree-text {
		font-size: 10px;
		margin-left: 10px; } }



.brands-partners {
	background-color: #33333f;
	padding: 20px 0;
	.item {
		margin: 0 10px;
		text-align: center;
		img {
			max-height: 100px;
			display: inline-block; } } }

.contact-block {
	text-align: center;
	padding: 40px 20px;
	.item {
		flex-basis: 33%;
		border-right: 1px solid $accent;
		&:last-child {
			border-right: none; } }
	h5 {
		color: #a7a7a7;
		margin-bottom: 5px; }
	a, p {
		color: $accent;
		font-weight: bold; }
	.img-block {
		margin-bottom: 20px;
		span {
			font-size: 40px;
			&:before {
				color: $accent; } } } }

.prev-works {
	margin: 40px 0;
	.wrap-items {
		justify-content: space-between; }
	.item {
		flex-basis: 33%;
		padding: 15px 5px;
		color: #fff; }
	.wrap-item {
		position: relative;
		max-height: 205px;
		overflow: hidden; }
	.lay-block {
		position: absolute;
		width: 50%;
		height: 100%;
		top: 0;
		right: 0;
		background-color: rgba(0,0,0,.7);
		display: flex;
		flex-direction: column;
		h4 {
			text-transform: uppercase;
			padding-left: 10px;
			margin: 10px 0; }
		p {
			color: #bbb;
			padding-left: 10px;
			font-size: 14px; } }
	.button-block {
		margin: auto 0 30px;
		.button.accent {
			font-size: 10px; } } }
strong {
	font-weight: bold; }
.our-advantages {
	background: $accent;
	color: #fff;
	padding: 50px 0;
	text-align: center;
	.title {
		font-size: 40px;
		margin: 30px 0 0; }
	.descr {
		text-transform: uppercase;
		font-weight: bold; }
	span[class^="icon"] {
		font-size: 40px;
		&:before {
			color: #fff; } }
	.item {
		padding: 0 15px;
		flex-basis: 25%; } }
.descr-advantages {
	padding-top: 40px;
	.wrap-items {
		flex-wrap: wrap; }
	.item {
		flex-basis: 50%;
		padding: 0 15px 40px; }
	h3 {
		font-weight: bold;
		font-size: 18px; }
	.wrap-item {
		display: flex; }
	.img-block {
		flex-basis: 75px;
		min-width: 75px;
		font-size: 36px;
		padding-top: 30px;
		span {
			&:before {
				color: $accent; } } } }

.s-type-balcony {
	.wrap-items {
		justify-content: space-between; }
	.item {
		flex-basis: 33%;
		text-align: center; }
	.descr {
		color: #A9A9A9;
		font-size: 14px; }
	.img-block {
		margin: 15px 0; }
	.name-profile {
		color: $accent;
		font-weight: bold; }
	.price-profile {
		margin-top: 15px;
		span {
			font-size: 22px;
			font-weight: bold;
			color: $accent;
			display: block;
			line-height: 25px; } } }

.s-part-balcony, .small-slider {
	h5 {
		color: $accent;
		max-width: 145px;
		margin: 10px auto; }
	.item {
		text-align: center;
		img {
			max-height: 100px;
			display: inline-block; } }
	.slick-next {
		top: 36%;
		right: 10px;
		z-index: 99;
		&:before {
			color: $accent;
			font-size: 30px; } }

	.slick-prev {
		top: 36%;
		left: 10px;
		z-index: 99;
		&:before {
			color: $accent;
			font-size: 30px; } } }

.call-master {
	background: $accent;
	font-size: 24px;
	color: #fff;
	.form-block {
		padding-top: 30px;
		padding-left: 15px;
		max-width: 580px; }
	strong {
		color: #000; }
	form {
		background-color: $accent;
		display: flex;
		justify-content: space-between;
		padding-left: 0;
		padding-right: 0;
		button,
		input {
			width: 100%;
			background-color: #fff;
			color: #000;
			font-size: 16px;
			font-weight: bold;
			height: 46px;
			display: inline-block; } }
	button {
		transition: all .3s;
		&:hover {
			background-color: transparent;
			color: #fff;
			border: 2px solid #fff; } }
	label {
		min-width: 48%;
		flex-basis: 48%; }
	.button-block {
		min-width: 48%;
		flex-basis: 48%; } }
.popup-block {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.75); }
#callBack {
	max-width: 600px;
	margin: auto;
	position: relative; }
.wrap-pop {
	display: none; }
.mod-close {
	font-size: 30px;
	position: absolute;
	right: 15px;
	top: 10px;
	cursor: pointer; }
.s-reg {
	.wrap-image {
		position: relative;
		width: 550px;
		margin: 50px auto 30px; }
	.descr {
		font-size: 12px; }
	.reg1 {
		position: absolute;
		top: -10px;
		left: -100px; }
	.reg2 {
		position: absolute;
		top: 50px;
		left: -115px; }
	.reg3 {
		position: absolute;
		top: 200px;
		left: -100px; }
	.reg4 {
		position: absolute;
		top: 0;
		right: -145px; }
	.reg5 {
		position: absolute;
		top: 200px;
		right: -135px; } }


footer {
	background-color: #3b3b3b;
	color: #fff;
	text-align: center;
	padding: 30px 0; }































.mobil-mnu {
	background-color: #fff;
	color: #000;
	display: none; }
.toggle-mnu {
	display: block;
	width: 28px;
	height: 28px;


	span:after, span:before {
		content: "";
		position: absolute;
		left: 0;
		top: 9px; }

	span:after {
		top: 18px; }

	span {
		position: relative;
		display: block; }

	span, span:after, span:before {
		width: 100%;
		height: 2px;
		background-color: #000;
		transition: all 0.3s;
		backface-visibility: hidden;
		border-radius: 2px; }


	&.on span {
		background-color: transparent; }

	&.on span:before {
		transform: rotate(45deg) translate(-1px, 0px); }

	&.on span:after {
		transform: rotate(-45deg) translate(6px, -7px); } }

/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////  квиз



.wrap-calc {
	max-width: 1140px;
	padding: 60px 0;
	margin: 0 auto;
	font-size: 14px;
	h2 {
		text-align: center;
		margin-bottom: 30px; }
	.preview-images {
		text-align: center;
		label {
			border: 1px solid #000;
			display: inline-block;
			cursor: pointer;
			transition: all .3s;
			&:hover {
				background: #FFE9A1; }
			&.active {
				background: #f7b70b; }
			input {
				display: none; } } }
	.big-pic {
		position: relative; }
	.output-item {
		position: relative;
		display: none;
		&.show-item {
			display: inline-block; } }
	.dinamic-block {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0; }
	.open-type {
		position: absolute;
		top: 45px;
		left: 40px;
		width: 107px;
		background-color: #F3F9FC;
		label {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			-ms-align-items: center;
			align-items: center;
			min-height: 20px;
			text-transform: uppercase;
			font-size: 10px;
			padding: 5px;
			span {
				margin-left: 10px;
				font-weight: bold;
				line-height: 1; } } }
	.two-window {
		.open-type-left {
			left: auto;
			right: 40px; } }
	.wrap-items {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex; }
	.button {
		display: inline-block;
		color: #fff;
		background-color: #f7b70b;
		padding: 8px 40px;
		text-align: center;
		min-width: 200px;
		border-radius: 15px;
		cursor: pointer;
		border: none;
		&:hover {
			opacity: .8; } }
	.but-first {
		text-align: center;
		margin-bottom: 20px; }
	.wrap-slider {
		position: relative; }
	.prev-balcons {
		img {
			min-height: 42px; } }
	.second-slide {
		max-width: 600px;
		margin: 0 auto;
		text-align: center;
		.descr {
			font-size: 20px;
			font-weight: bold;
			margin: 15px 0; } }
	.work-items {
		display: inline-block;
		text-align: left; }
	.wrap-works {
		label {
			display: block;
			padding: 5px 0;
			cursor: pointer;
			font-size: 16px; } } }
.wrap-calc .open-type label:hover, .wrap-calc .open-type label.active {
	background: #f7b70b; }
.big-pic {
	position: relative;
	.dinamic-pic {
		position: absolute;
		top: 15px;
		left: 13px;
		transition: all 1s;
		display: none; } }
.two-window {
	.dinamic-block-left {
		.dinamic-pic {
			left: auto;
			right: 13px; } } }
.twoTop-window {
	.dinamic-block-right {
		.dinamic-pic {
			left: 10px;
			top: 120px; }
		.open-type {
			top: 145px;
			left: 38px; } }
	.dinamic-block-left {
		.dinamic-pic {
			left: auto;
			right: 10px;
			top: 120px; }
		.open-type {
			top: 145px;
			left: auto;
			right: 38px; } } }
.third-window {
	.dinamic-block-left {
		.dinamic-pic {
			left: auto;
			right: 12px; }
		.open-type {
			left: auto;
			right: 40px; } }
	.dinamic-block-right {
		.dinamic-pic {
			left: 10px; } }
	.dinamic-block-center {
		.dinamic-pic {
			left: 175px; }
		.open-type {
			left: 202px; } } }
.balcon {
	.window-content {
		display: none; }
	.balkony-content {
		display: block; } }
.big-window {
	.dinamic-block-right {
		.dinamic-pic {
			left: 10px; } }
	.dinamic-block-left {
		.dinamic-pic {
			left: 175px; }
		.open-type {
			left: 200px; } } }
.y-slider {
	display: inline-block;
	margin-right: 30px;
	.ui-widget {
		&.ui-widget-content {
			height: 300px;
			background: url(../img/scale_v.png) repeat-y; } }
	.ui-widget-header {
		background: url(../img/scale_v.png) repeat-y; } }
.y-start {
	position: absolute;
	bottom: -18px;
	left: -7px;
	font-size: 14px; }
.y-end {
	position: absolute;
	top: -18px;
	left: -7px;
	font-size: 14px; }
.x-start {
	position: absolute;
	top: -3px;
	left: -32px;
	font-size: 14px; }
.x-end {
	position: absolute;
	top: -3px;
	right: -32px;
	font-size: 14px; }
.x-slider {
	min-width: 600px;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 99;
	.ui-widget {
		&.ui-widget-content {
			background: url(../img/scale_h.png) repeat-x; } }
	.ui-widget-header {
		background: url(../img/scale_h.png) repeat-x; } }
.wrap-output {
	margin-top: 40px; }
.wrap-calc #amount, .wrap-calc #amount2, #balcone-width, #balcone-height {
	display: none; }
.slider-block {
	position: relative; }
.gabarites {
	margin-right: 30px;
	p {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px; }
	label {
		position: relative;
		display: block;
		&:before {
			content: "mm";
			position: absolute;
			right: -20px;
			top: 20px;
			font-size: 14px; }
		span {
			display: block; } }
	input {
		max-width: 60px;
		text-indent: 10px;
		height: 30px; } }
table {
	max-width: 100%;
	background-color: transparent;
	border-collapse: collapse;
	border-spacing: 0; }
.ps1 {
	td {
		&:nth-child(1) {
			font-size: 17px;
			color: #949494; } } }
.ps5 {
	td {
		&:nth-child(1) {
			font-size: 17px;
			color: #dcc004; } } }
.ps2 {
	td {
		&:nth-child(1) {
			font-size: 17px;
			color: #00a2f0; } } }
.ps3 {
	td {
		&:nth-child(1) {
			font-size: 17px;
			color: #d4532f; } } }
.ps4 {
	td {
		&:nth-child(1) {
			font-size: 17px;
			color: #de9000; } } }
.calc_window_profile_selector {
	position: relative;
	table {
		width: 220px;
		font-size: 14px;
		border: 1px solid #ccc;
		border-radius: 3px;
		td {
			padding: 10px 0px 17px 19px;
			white-space: nowrap;
			cursor: pointer;
			p {
				white-space: normal; } } }
	.profile {
		cursor: pointer;
		img {
			margin: -17px 5px -23px -20px; } }
	td {
		border-top: 1px solid #ddd;
		font-weight: 600;
		a {
			font-weight: normal;
			font-size: 14px;
			color: #959595; } } }
.ps1 .active, .ps1 .profile:hover, .ps1 .profile_b:hover {
	background: #9b9b9b;
	background-image: linear-gradient(#c0c0c0 0%, #9b9b9b 100%);
	background-position: 0px -10px;
	background-repeat: no-repeat; }
.profile, .profile_b {
	height: 25px;
	width: 320px;
	min-width: 110px; }
.calc_window_profile_selector .profile:hover a, .calc_window_profile_selector .profile_b:hover a, .calc_window_profile_selector .active a {
	color: #fff; }
.ps5 .active, .ps5 .profile:hover, .ps5 .profile_b:hover {
	background: #caca3f;
	background-image: linear-gradient(#d7d954 0%, #c4ca3f 100%);
	background-position: 0px -10px;
	background-repeat: no-repeat; }
.ps2 .active, .ps2 .profile:hover, .ps2 .profile_b:hover {
	background: #3b93bf;
	background-image: linear-gradient(#00a6f6 0%,#3b93bf 100%);
	background-position: 0px -10px;
	background-repeat: no-repeat; }
.ps3 .active, .ps3 .profile:hover, .ps3 .profile_b:hover {
	background: #bf552c;
	background-image: linear-gradient(#d76433 0%, #bf552c 100%);
	background-position: 0px -10px;
	background-repeat: no-repeat; }
.ps4 .active, .ps4 .profile:hover, .ps4 .profile_b:hover {
	background: #caa03f;
	background-image: linear-gradient(#dab155 0%, #caa03f 100%);
	background-position: 0px -10px;
	background-repeat: no-repeat; }
.calc_window_profile_info {
	padding: 10px;
	width: 290px;
	border: 4px solid #eee;
	background: #fff;
	z-index: 10000;
	border: 1px solid #ddd;
	position: absolute;
	left: -290px;
	top: 0;
	display: none;
	h4 {
		font-size: 22px;
		display: block;
		margin: 10px 5px; }
	li {
		margin-left: 15px; }
	.profile_img_container {
		flex-basis: 100px;
		min-width: 100px;
		margin-right: 10px;
		img {
			margin: 0; } }
	.progress {
		padding: 3px;
		margin-bottom: 8px;
		background: #eee;
		border-radius: 5px;
		color: #fff;
		div {
			padding: 1px;
			text-align: left;
			padding-left: 10px;
			border-radius: 3px; } }
	.wrap-top {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex; }
	.progress-block {
		flex-grow: 1; } }
.calc_window_profile {
	display: inline-block;
	text-align: left; }
.wrap-kharakteristik {
	flex-grow: 1;
	text-align: right; }
.profile {
	&:hover {
		.calc_window_profile_info {
			display: block; } } }
#param1 {
	background-color: orange; }
#param2 {
	background-color: green; }
#param3 {
	background-color: blue; }
.slide {
	&:not(.slide-1) {
		display: none; } }
.wrap-form {
	display: none;
	width: 600px;
	margin: 0 auto;
	padding: 40px 20px;
	background-color: #eee;
	label {
		display: block;
		margin: 15px 0;
		span {
			display: block; } }
	input {
		width: 100%;
		display: block;
		height: 40px;
		text-indent: 10px; } }
.button-block-next {
	margin-top: 0;
	text-align: right;
	.button {
		position: relative;
		top: -50px;
		width: 220px;
		border: 1px solid #000; } }
.balkony-content {
	display: none;
	.preview-images {
		img {
			min-height: 42px; } }
	.big-pic {
		img[data-num="2"] {
			display: none; } }
	.x-slider {
		bottom: -40px; }
	.wrap-dropdown {
		display: none;
		label {
			display: block;
			padding-left: 20px; } } }




@import "media"; // Всегда в конце
